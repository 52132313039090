<template>
  <div>
    <v-tabs class="mb-4" v-model="tab" color="accent" lazy-validation>
      <v-tab> Meter Information </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <div :key="meterID" class="container">
          <div>
            <div>Meter Type</div>
            <span v-if="form.meterType === 'Electricity'" class="ml-5">
              <v-icon class="mt-2" color="amber">mdi-lightbulb</v-icon>
            </span>
            <span v-if="form.meterType === 'Gas'" class="ml-5">
              <v-icon color="red">mdi-fire</v-icon>
            </span>
            <span v-if="form.meterType === 'Water'" class="ml-5">
              <v-icon color="blue">mdi-water</v-icon>
            </span>
          </div>
          <SupplyNumber
            class="mt-3"
            v-if="!estimate"
            :type="form.meterType"
            :supply-number="form.supplyNumber"
            :supplyNumberDidabled="true"
            :pc="form.pc"
            :mtc="form.mtc"
            :llf="form.llf"
          ></SupplyNumber>
          <div
            v-if="
              pcLengthError &&
                supplyDetails.type === 'Electricity' &&
                (!supplyDetails.pc ||
                  (supplyDetails.pc && supplyDetails.pc.length < 2))
            "
            class="mt-2"
          >
            <span class="red--text">PC length should be 2.</span>
          </div>
          <div
            v-if="
              mtcLengthError &&
                supplyDetails.type === 'Electricity' &&
                (!supplyDetails.mtc ||
                  (supplyDetails.mtc && supplyDetails.mtc.length < 3))
            "
            class="mt-2"
          >
            <span class="red--text">MTC length should be 3.</span>
          </div>
          <div
            v-if="
              llfLengthError &&
                supplyDetails.type === 'Electricity' &&
                (!supplyDetails.llf ||
                  (supplyDetails.llf && supplyDetails.llf.length < 3))
            "
            class="mt-2"
          >
            <span class="red--text">LLF length should be 3.</span>
          </div>

          <v-select
            :key="selectedSupplierId"
            class="mt-3"
            :items="filteredSuppliers"
            item-text="text"
            item-value="value"
            v-model="selectedSupplierId"
            label="Supplier"
            @change="selectSupplier(selectedSupplierId)"
          >
          </v-select>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="d-inline-block mr-2"
                type="tel"
                v-model="contractEndDate"
                label="Contract End Date"
                v-mask="'##/##/####'"
                @input="contractEndDateChanged"
              ></v-text-field>

              <v-icon class="d-inline-block mr-2" v-bind="attrs" v-on="on">
                mdi-calendar
              </v-icon>
            </template>
            <v-date-picker
              ref="picker"
              v-model="form.contractEndDate"
              min="1950-01-01"
              @change="save"
              locale="en-GB"
            ></v-date-picker>
          </v-menu>
          <v-divider class="mt-2"></v-divider>
          <v-row class="mt-3" v-if="!this.estimate">
            <h3 class="v-list-item__title text-h5 text-capitalize font-weight-medium ml-3 mb-3" style="font-weight: bold; font-size: 17px !important;">
              Meter Documents
              <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="primary ml-3" style="font-size:20px;">mdi-information</v-icon>
              </template>
              <span>Please ensure that you only add or edit meters with the correct meter documents attached. <br>
              All adding and editing of meters are monitored regularly and misuse can result in <span class="font-weight-medium"> disciplinary action. </span></span>
            </v-tooltip>
            </h3>
            <v-col cols="12" class="py-0">
              <div class="data-displayer">
                <v-text-field
                  ref="description"
                  v-model="description"
                  :rules="descriptionRules"
                  label="Description"
                  size="sm"
                ></v-text-field>
              </div>
            </v-col>
            <v-col cols="12" class="py-0">
              <div class="data-displayer">
                <v-select
                  ref="uploadSelected"
                  v-model="uploadSelected"
                  size="sm"
                  :rules="uploadSelectedRules"
                  :items="uploads"
                  placeholder="Please Select"
                  label="Upload Type"
                >
                  <template slot="item" slot-scope="data">
                    {{ data.item.text }}
                    <v-chip
                      v-if="data.item.valid"
                      class="ma-2"
                      x-small
                      color="green"
                      text-color="white"
                    >
                      VALID
                    </v-chip>
                  </template>
                </v-select>
              </div>
            </v-col>
            <v-col cols="12">
              <v-file-input
                ref="file"
                label="Choose a file"
                id="file"
                :rules="fileRules"
                v-model="file"
              ></v-file-input>
            </v-col>
          </v-row>

          <div class="mt-4">
            <v-btn
              depressed
              color="accent"
              block
              dark
              large
              @click="saveOptions"
            >
              Save Changes
            </v-btn>
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>
    <!-- Confirmation Modal -->
    <v-dialog v-model="visible" width="450">
      <v-card>
        <v-card-title class="text-capitalize">
          Edit Confirmation
        </v-card-title>

        <v-card-text class="text-center  black--text " style="font-size:13px">
          <div>
            You are about to edit an Actual Meter which should only be done if
            the customer has confirmed the information from a bill or we have a
            recent information request returned. All edited Actuals are
            monitored regularly and misuse can result in disciplinary action.
            <br />
            Are you sure you want to make this change ?
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="green" text @click="submitForm"> YES </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="closeModal"> NO </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { mask } from "vue-the-mask"

import api from "@/store/api"
import { changesSaved, somethingWentWrong } from "@/helpers/utilities"
import SupplyNumber from "@/components/supply-number"
import { mergeExistingEntriesIntoForm } from "@/helpers/utilities"
// import api from "@/store/api"
import dayjs from "dayjs"

export default {
  name: "MeterInfoEdit",
  data: () => ({
    pcLengthError: false,
    mtcLengthError: false,
    llfLengthError: false,
    tab: null,
    meterID: null,
    suppliers: [],
    selectedSupplierId: null,
    supplier: [],
    menu: false,
    contractEndDate: "",
    estimate: false,
    form: {
      meterType: "",
      supplyNumber: "",
      pc: "",
      mtc: "",
      llf: "",
      contractEndDate: "",
      supplierName: "",
      renewalDate: "",
      oldContractEndDate: "",
      estimateActual: ""
    },
    visible: false,
    selectedSupplier: {
      supplierId: 0,
      supplierName: "",
      active: false,
      internalLogo: "",
      meterType: [],
      electricity: 0,
      gas: 0,
      customerTelephone: "",
      brokerSupportTelephone: "",
      supplierWebsite: "",
      terminationEmail: "",
      cotEmail: "",
      generalEmail: "",
      uhtpiAgreement: true,
      uhDirect: true,
      uhSubBroker: false,
      uhSubBrokerPathway: 0,
      csstpiAgreement: false,
      cssDirect: false,
      cssSubBroker: false,
      cssSubBrokerPathway: 0
    },
    descriptionRules: [value => !!value || "Please Add a Description"],
    uploadSelectedRules: [value => !!value || "Please Select"],
    fileRules: [value => !!value || "Please Upload a File"],
    description: "",
    oldDescription: "",
    uploadSelected: 0,
    oldUploadSelected: 0,
    file: "",
    uploads: []
  }),
  directives: { mask },
  props: {
    meterId: Number,
    renewalDate: {
      type: Number,
      default: () => 0
    }
    // addressId: Number
  },
  components: {
    SupplyNumber
  },
  computed: {
    ...mapGetters("forms", ["supplyDetails"]),
    // ...mapGetters("supplier", ["suppliersList"]),
    ...mapGetters("addresses", ["selectedAddress", "primaryAddress"]),
    ...mapGetters("meters", ["selectedMeter", "meters"]),
    ...mapGetters("ui", ["formEditMode"]),
    ...mapGetters("employee", ["userId"]),
    ...mapGetters("company", ["companyId"]),

    getMeterTYpeID() {

      if(this.form.meterType === 'Electricity') return 9
      else if(this.form.meterType === 'Gas') return 10
      else if(this.form.meterType === 'Water') return 2705
      else return -1
    },

    filteredMeterForDocuments() {
      const filteredMeter = this.meters.filter(x => {
        return x.meterId === this.supplyDetails.meterId
      })
      return filteredMeter
    },
    formattedDate() {
      return this.form.contractEndDate
        ? dayjs(this.form.contractEndDate).format("DD/MM/YYYY")
        : ""
    },
    // filteredSuppliers() {
    //   if (this.form.meterType === "Electricity") {
    //     const filteredSuppliersArray = this.suppliers.filter(
    //       supplier => supplier.electricity === 1 && supplier.active === true
    //     )
    //     return filteredSuppliersArray.map(option => ({
    //       text: option.supplierName,
    //       value: option.supplierId
    //     }))
    //   }
    //   const filteredSuppliersArray = this.suppliers.filter(
    //     supplier => supplier.gas === 1 && supplier.active === true
    //   )
    //   return filteredSuppliersArray.map(option => ({
    //     text: option.supplierName,
    //     value: option.supplierId
    //   }))
    // }
    filteredSuppliers() {
      const filteredSuppliersArray = this.suppliers.map(option => ({
        text: option.supplierName,
        value: option.supplierId
      }))

      return filteredSuppliersArray
    },
  },
  watch: {
    supplyDetails(newVal, oldVal) {
      this.getContractUploadTypes()
      if (
        newVal.meterId !== oldVal.meterId ||
        newVal.contractEndDate !== oldVal.contractEndDate ||
        newVal.supplierId !== oldVal.supplierId
      ) {
        this.estimate = newVal.estimate
        this.meterID = newVal.meterId
        this.form.meterType = newVal.type
        this.form.supplyNumber = newVal.supplyNumber
        this.form.pc = newVal.pc
        this.form.mtc = newVal.mtc
        this.form.llf = newVal.llf
        this.fetchSuppliers()
        this.selectedSupplierId = parseInt(newVal.supplierId)
        this.form.contractEndDate = newVal.contractEndDate
          ? dayjs(newVal.contractEndDate * 1000).format("YYYY-MM-DD")
          : ""
        this.form.oldContractEndDate = newVal.contractEndDate
          ? dayjs(newVal.contractEndDate * 1000).format("YYYY-MM-DD")
          : ""
        this.contractEndDate = newVal.contractEndDate
          ? dayjs(newVal.contractEndDate * 1000).format("DDMMYYYY")
          : ""

        this.description = this.filteredMeterForDocuments[0].description
        this.oldDescription = this.filteredMeterForDocuments[0].description
        this.file = this.filteredMeterForDocuments[0].fileName
        this.uploadSelected = this.filteredMeterForDocuments[0].meterDocumentTypeId
        this.oldUploadSelected = this.filteredMeterForDocuments[0].meterDocumentTypeId
      }
    }
  },
  created() {
    this.bindMeterDetails()
    this.intializeComponent()
    this.getContractUploadTypes()
  },
  mounted() {
    this.meterID = this.meterId
    // this.fetchMeters()
    this.fetchSuppliers()
    this.fetchDocuments()

    console.log("meterDetailsfromForm", this.meters)
  },
  methods: {
    ...mapActions("ui", ["closeForm", "openForm"]),
    ...mapActions("meters", [
      "fetchMetersAfterRemove",
      "fetchPrimaryMetersAfterSave"
    ]),
    ...mapActions("notes", ["fetchNotes"]),
    ...mapActions("forms", ["fetchMainData"]),
    ...mapActions("ui", ["openForm"]),
    openConfirmModal() {
      // this.details(this.companyId),
      this.visible = true
    },
    isSelectedDocumentValid() {
      var uploadType = this.uploads.find(x => x.value == this.uploadSelected)
      console.log("uploadType", uploadType)
      return uploadType === null || uploadType === undefined ? false : uploadType.valid
    },
    async getContractUploadTypes() {
      const res = await api.get("getMeterDocuementType")
      console.log("meter typ docs", res)
      if (res && res.status === 200) {
        // this.uploads = res.data.filter(x => {
        //   return x.valid
        // })
        this.uploads = res.data.map(option => ({
          text: option.value,
          value: option.id,
          valid: option.valid
        }))
      }
    },
    saveOptions() {
      this.pcLengthError = false
      this.mtcLengthError = false
      this.llfLengthError = false


      if (!this.estimate) {

        if (
          this.supplyDetails.type === "Electricity" &&
          (!this.supplyDetails.pc ||
            (this.supplyDetails.pc && this.supplyDetails.pc.length < 2) ||
            !this.supplyDetails.mtc ||
            (this.supplyDetails.mtc && this.supplyDetails.mtc.length < 3) ||
            !this.supplyDetails.llf ||
            (this.supplyDetails.llf && this.supplyDetails.llf.length < 3))
        ) {
          if (
            !this.supplyDetails.pc ||
            (this.supplyDetails.pc && this.supplyDetails.pc.length < 2)
          ) {
            this.pcLengthError = true
          }
          if (
            !this.supplyDetails.mtc ||
            (this.supplyDetails.mtc && this.supplyDetails.mtc.length < 3)
          ) {
            this.mtcLengthError = true
          }
          if (
            !this.supplyDetails.llf ||
            (this.supplyDetails.llf && this.supplyDetails.llf.length < 3)
          ) {
            this.llfLengthError = true
          }
          return false
        }
      }



      if (!this.estimate) {
        if (
          this.description === "" ||
          this.uploadSelected === "" ||
          this.uploadSelected === 0 ||
          this.file === "" ||
          this.file === 0
        ) {
          const form = {
            description: this.description,
            uploadSelected: this.uploadSelected,
            file: this.file
          }
          this.formHasErrors = false
          Object.keys(form).forEach(f => {
            if (!this.form[f]) this.formHasErrors = true
            this.$refs[f].validate(true)
          })
          return false
        }
      }
      this.submitForm()
    },
    closeModal() {
      this.visible = false
      this.intializeComponent()
    },
    intializeComponent() {
      this.fetchSuppliers()
      console.log("suppleirDetails", this.supplyDetails)
      this.estimate = this.supplyDetails.estimate
      this.form.meterType = this.supplyDetails.type
      this.form.supplyNumber = this.supplyDetails.supplyNumber
      this.form.pc = this.supplyDetails.pc
      this.form.mtc = this.supplyDetails.mtc
      this.form.llf = this.supplyDetails.llf
      this.selectedSupplierId = parseInt(this.supplyDetails.supplierId)
      this.form.contractEndDate = this.supplyDetails.contractEndDate
        ? dayjs(this.supplyDetails.contractEndDate * 1000).format("YYYY-MM-DD")
        : ""
      this.form.oldContractEndDate = this.supplyDetails.contractEndDate
        ? dayjs(this.supplyDetails.contractEndDate * 1000).format("YYYY-MM-DD")
        : ""
      this.contractEndDate = this.supplyDetails.contractEndDate
        ? dayjs(this.supplyDetails.contractEndDate * 1000).format("DDMMYYYY")
        : ""

      this.description = this.filteredMeterForDocuments[0].description
      this.oldDescription = this.filteredMeterForDocuments[0].description
      this.file = this.filteredMeterForDocuments[0].fileName
      this.uploadSelected = this.filteredMeterForDocuments[0].meterDocumentTypeId
      this.oldUploadSelected = this.filteredMeterForDocuments[0].meterDocumentTypeId
    },
    async fetchSuppliers() {
      const res = await api.get(`suppliers/${this.getMeterTYpeID}`)
      // console.log('metererereererere',res)
      if (res.status === 200) {
        console.log(res.data)
        this.suppliers = res.data
      }
    },
    selectSupplier(supplierId) {
      const supplier = this.suppliers.find(
        supplier => supplier.supplierId === supplierId
      )
      this.selectedSupplier = supplier
    },
    bindMeterDetails() {
      if (this.formEditMode) {
        Object.entries(this.meters).forEach(
          mergeExistingEntriesIntoForm(this.form)
        )
      }
    },
    validate() {
      this.$refs.form.validate()
    },
    save() {
      this.contractEndDate = dayjs(this.form.contractEndDate).format("DDMMYYYY")
    },
    contractEndDateChanged() {
      if (this.contractEndDate.length >= 1) {
        const tempDay = this.contractEndDate.slice(0, 2)
        const tempMonth = this.contractEndDate.slice(3, 5)
        const tempYear = this.contractEndDate.slice(6, 9)
        // const d = new Date()
        // const minYear = d.getFullYear();

        if (parseInt(tempDay) > 31) {
          const temp = this.contractEndDate.slice(2, 10)
          this.contractEndDate = "31" + temp
        }

        if (this.contractEndDate.length >= 2 && parseInt(tempDay) === 0) {
          const temp = this.contractEndDate.slice(2, 10)
          this.contractEndDate = "01" + temp
        }

        if (parseInt(tempMonth) > 12) {
          const temp = this.contractEndDate.slice(5, 10)
          this.contractEndDate = tempDay + "/12" + temp
        }

        if (this.contractEndDate.length >= 5 && parseInt(tempMonth) === 0) {
          const temp = this.contractEndDate.slice(5, 10)
          this.contractEndDate = tempDay + "/01" + temp
        }

        if (parseInt(tempYear) <= 0) {
          // const temp = this.contractEndDate.slice(6, 10)
          this.contractEndDate = tempDay + tempMonth + "/2021"
        }
        // if(this.form.contractEndDate.length >= 7 && parseInt(tempYear) === 0) {
        //     const temp = this.contractEndDate.slice(6, 10)
        //     this.contractEndDate = minYear + temp
        // }
        const contractEndDate = this.contractEndDate
          .split("/")
          .reverse()
          .join("-")
        const currentEndDate = dayjs(contractEndDate).format("YYYY-MM-DD")
        this.form.contractEndDate = currentEndDate
        if (this.form.contractEndDate === "Invalid Date") {
          this.form.contractEndDate = ""
        }
      }
    },
    async submitForm() {
      // const noteMessage = this.selectedSection
      const isSupplierSelected =
        this.selectedSupplier.supplierId === 0
          ? ""
          : this.supplyDetails.supplierId === this.selectedSupplier.supplierId
          ? ""
          : `Supplier - ${this.supplyDetails.supplierName} to  ${this.selectedSupplier.supplierName}`
      const isCedSelected =
        this.form.oldContractEndDate === this.form.contractEndDate
          ? ""
          : `CED - ${dayjs(this.form.oldContractEndDate).format("DD/MM/YYYY")}
                              to ${dayjs(this.form.contractEndDate).format(
                                "DD/MM/YYYY"
                              )}`

       console.log("form", this.form)                   
       console.log("form", this.supplyDetails)                   

      const isMPANChanged =
        (
          this.form.pc === this.supplyDetails.pc
          && this.form.mtc === this.supplyDetails.mtc
          && this.form.llf === this.supplyDetails.llf
        )
          ? ""
          : `MPAN - ${this.form.pc}${this.form.mtc}${this.form.llf}
                              to ${this.supplyDetails.pc}${this.supplyDetails.mtc}${this.supplyDetails.llf}`


      const descriptionChanged = this.oldDescription === this.description 
      ? "" : `Meter Documents Description - ${this.oldDescription}
                              to ${this.description}`

      const uploadType = this.uploads.find(x => x.value == this.uploadSelected)
      const oldUploadType = this.uploads.find(x => x.value == this.oldUploadSelected)

      const uploadTypeChanged = this.oldUploadSelected === this.uploadSelected 
      ? "" : `Upload Type - ${oldUploadType ? oldUploadType.text : ""}
                              to ${uploadType ? uploadType.text : ""}`




      const selectedSupplyNumber = this.form.supplyNumber
        ? this.form.supplyNumber
        : "Estimated"
        const mpanChanged = isMPANChanged === "" ? "" : isMPANChanged + "<br/>"
      const selectedSupplier =
        isSupplierSelected === "" ? "" : isSupplierSelected + "<br/>"
      const isSomethingChanged =
        selectedSupplier === "" && isCedSelected === "" && mpanChanged === "" ? false : true
      const noteMessage = isSomethingChanged
        ? `Meter Edited - ${selectedSupplyNumber} <br/>
                          ${selectedSupplier}
                          ${isCedSelected}
                          ${mpanChanged}
                          ${descriptionChanged}
                          ${uploadTypeChanged}`
        : ""

      const formData = new FormData()

      formData.append("File", this.file)
      formData.append(
        "MeterID",
        this.supplyDetails ? this.supplyDetails.meterId : 0
      )
      formData.append("AddressID", this.supplyDetails.addressId)
      formData.append(
        "EstimateActual",
        this.supplyDetails.estimate ? "Estimate" : "Actual"
      )
      formData.append("CurrentUserID", this.userId)
      formData.append("MeterTypeID", this.supplyDetails.meterTypeId)
      formData.append("PC", this.supplyDetails.pc)
      formData.append("MTC", this.supplyDetails.mtc)
      formData.append("LLF", this.supplyDetails.llf)
      formData.append("SupplyNumber", this.supplyDetails.supplyNumber)
      formData.append("SupplierID", this.selectedSupplierId)
      formData.append("CED", dayjs(this.form.contractEndDate).unix())
      formData.append("NoteMessage", noteMessage)
      formData.append("CompanyID", this.$store.getters["company/companyId"])
      formData.append("Description", this.description)
      formData.append("MeterDocumentTypeID", this.uploadSelected)
      formData.append("FileID", this.filteredMeterForDocuments[0].fileId)
      formData.append("UploadedBy", this.userId)
      formData.append("Valid", this.isSelectedDocumentValid())

      console.log("formadata", formData)

      try {
        const response = await api.post("saveMeterWithDocument", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        if (response.status === 200) {
          changesSaved("Meter Successfully Updated!")
          setTimeout(() => {
            this.fetchMainData(this.companyId)
            this.fetchMetersAfterRemove(this.selectedAddress.addressId)
            this.fetchPrimaryMetersAfterSave(this.companyId)
            this.fetchNotes()
            this.closeForm()
          }, 2000)
        }
      } catch (err) {
        somethingWentWrong(err)
        console.log("errro", err)
      }
    },

    async uploadContract() {
      const formData = new FormData()
      // const file = 0
      formData.append("ID", 0)
      formData.append("MeterID", this.supplyDetails.meterId)
      formData.append("File", this.file)
      formData.append("CompanyID", this.companyId)
      formData.append("Description", this.description)
      formData.append("MeterDocumentTypeID", this.uploadSelected)
      formData.append("UploadedBy", this.userId)

      const response = await api.post("uploadMeterDocument", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      if (response.status === 200) {
        this.description = ""
        this.oldDescription = ""
        this.uploadSelected = 0
        this.oldUploadSelected = 0
        this.file = ""
        changesSaved("Document Successfully Uploaded!")
      }
    }
  }
}
</script>
